import useImage from '@hooks/useImage';
import classNames from 'classnames';
import React, { type FC } from 'react';
import { PlayIcon } from './PlayIcon';
import ThumbnailImage from './ThumbnailImage';
import { PLACEHOLDER_CONSTANTS } from './constants';
import type { PlayableRenderProps } from './playable';
import Styles from './styles.scss';

export interface PlaceholderVideoProps
	extends Pick<PlayableRenderProps, 'playMedia'> {
	canPlay: boolean;
	containerClassName?: string;
	mediaItem: Vendor.VideoMedia;
	preload?: boolean;
	lazy?: boolean;
	tabIndexOverride?: number;
}

export const PlaceholderVideo: FC<PlaceholderVideoProps> = (props) => {
	const {
		canPlay,
		containerClassName,
		playMedia,
		tabIndexOverride = 0,
	} = props;
	const [imageStatus, onLoad, onError] = useImage(PLACEHOLDER_CONSTANTS.url);

	const startPlaying = () => {
		if (canPlay) {
			playMedia();
		}
	};

	return (
		<div
			onClick={startPlaying}
			className={containerClassName}
			onKeyPress={startPlaying}
			tabIndex={tabIndexOverride}
			role="button"
		>
			<div
				className={
					imageStatus !== 'INIT'
						? Styles.videoPlaceholder
						: Styles.videoPlaceholderLoading
				}
			>
				<ThumbnailImage
					{...props}
					imageClass={Styles.videoThumbnail}
					imageStatus={imageStatus}
					onError={onError}
					onLoad={onLoad}
				/>
				<div className={classNames(Styles.videoLightboxPosition)}>
					<PlayIcon />
				</div>
			</div>
		</div>
	);
};

export default PlaceholderVideo;
