import React, { type FC } from 'react';
import ReactPlayer from 'react-player/lazy';
import PlaceholderVideo from './PlaceholderVideo';
import Playable, { type PlayableRenderProps } from './playable';
import type { CommonImageProps } from './types';
import { getVideoClass } from './utils';

export interface VideoProps extends CommonImageProps {
	canPlay: boolean;
	mediaItem: Vendor.VideoMedia;
	height?: number;
	preload?: boolean;
	width?: number;
	lazy?: boolean;
	tabIndexOverride?: number;
}

const Video: FC<VideoProps> = (props) => {
	const { canPlay, mediaItem } = props;

	return (
		<Playable
			media={mediaItem}
			render={({
				media,
				playMedia,
				isPlaying,
				stopMedia,
				pause,
				unpause,
				isPaused,
			}: PlayableRenderProps) => {
				const containerClassName = getVideoClass(canPlay, isPlaying);
				return canPlay && isPlaying ? (
					<div className={containerClassName} data-testid="video-player">
						<ReactPlayer
							url={media.url}
							playing={isPlaying && !isPaused}
							controls
							onEnded={stopMedia}
							onPause={pause}
							onPlay={unpause}
						/>
					</div>
				) : (
					<PlaceholderVideo
						containerClassName={containerClassName}
						playMedia={playMedia}
						{...props}
					/>
				);
			}}
		/>
	);
};

export default Video;
