import { selectAssignment } from '@redux/experiments/selectors/index';
import type { StorefrontLightboxRevampAssignment } from 'types/experiments';
import type { State } from 'types/redux';

export const storefrontLightboxRevampAssignmentSelector = (state: State) => {
	// Only on storefronts
	if (state.page.pageType !== 'storefront') return null;

	return selectAssignment(state, [
		'storefrontLightboxRevamp',
	]) as StorefrontLightboxRevampAssignment;
};

export const isStorefrontLightboxRevampSelector = (state: State) => {
	const assignment = storefrontLightboxRevampAssignmentSelector(state);
	return assignment?.startsWith('variant1');
};
