import AnalyticsConstants from '@constants/analytics/index.js';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import type { Decorated } from 'types/vendor';
import VendorAnalytics from '../../../decorators/vendorAnalytics';
import { isReviewsLightboxAtom, lightboxMediaAtom } from '../atoms';

type ActionType =
	| 'clicked right'
	| 'clicked left'
	| 'overview photo clicked'
	| 'dismiss';

const getGalleryAnalytics = (vendor: Decorated) => {
	const vendorAnalytics = new VendorAnalytics(vendor);
	return {
		event: AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
		properties: {
			sourceContent: AnalyticsConstants.PHOTO_GALLERY,
			sourcePage:
				vendor.purchaseStatus === 'PAID'
					? AnalyticsConstants.PAID_STOREFRONT
					: AnalyticsConstants.UNPAID_STOREFRONT,
			...vendorAnalytics.baseEventProps(),
		},
	};
};

const getStorefrontReviewAnalytics = (vendor: Decorated) => {
	const vendorAnalytics = new VendorAnalytics(vendor);
	return {
		event: 'Review Interaction',
		properties: {
			sourceContent: 'individual review gallery',
			sourcePage:
				vendor.purchaseStatus === 'PAID'
					? AnalyticsConstants.PAID_STOREFRONT
					: AnalyticsConstants.UNPAID_STOREFRONT,
			...vendorAnalytics.baseEventProps(),
		},
	};
};

const getReviewHubAnalytics = (vendor: Decorated) => {
	const vendorAnalytics = new VendorAnalytics(vendor);
	return {
		event: 'Review Interaction',
		properties: {
			sourceContent: 'individual review gallery',
			sourcePage: 'Review Hub',
			...vendorAnalytics.baseEventProps(),
		},
	};
};

export const useTrackLightbox = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const isReviewsLightbox = useAtomValue(isReviewsLightboxAtom);
	const isReviewHub = useAppSelector(
		(state) => state.page.pageType === 'review wedding vendors',
	);
	const { event, properties } = useMemo(() => {
		if (isReviewHub) return getReviewHubAnalytics(vendor);
		if (isReviewsLightbox) {
			return getStorefrontReviewAnalytics(vendor);
		}
		return getGalleryAnalytics(vendor);
	}, [isReviewsLightbox, isReviewHub, vendor]);
	const { track } = useAnalyticsContext();
	const media = useAtomValue(lightboxMediaAtom);

	return useCallback(
		(action: ActionType, newIndex: number) => {
			track({
				event,
				properties: {
					action,
					mediaIndex: newIndex,
					mediaType: media?.[newIndex]?.mediaType,
					item: media?.[newIndex]?.url,
					...properties,
				},
			});
		},
		[track, event, properties, media],
	);
};
