import { useLightboxIsPlaying } from '@components/lightbox/hooks/use-is-playing';
import { useLightboxStatus } from '@components/lightbox/hooks/use-lightbox-status';
import {
	playingIdAtom,
	setIsPlayingAtom,
} from '@components/unified-lightbox/atoms';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { isStorefrontLightboxRevampSelector } from '@redux/experiments/selectors/storefront-lightbox-revamp';
import { useAppSelector } from '@redux/hooks';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import AnalyticsConstants from '../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../decorators/vendorAnalytics';

export interface PlayableRenderProps {
	playMedia: () => void;
	stopMedia: () => void;
	pause: () => void;
	unpause: () => void;
	media: Vendor.Media;
	isPlaying: boolean;
	isPaused: boolean;
}

interface StateProps {
	vendor: Vendor.Decorated;
}

interface OwnProps {
	render(props: PlayableRenderProps): JSX.Element;
	media: Vendor.Media;
}

export type PlayableProps = StateProps & OwnProps;

const Playable: React.FC<PlayableProps> = ({ render, media, vendor }) => {
	const { mediaType, id } = media;
	const { track } = useAnalyticsContext();
	const [isPaused, setIsPaused] = useState(false);

	let setLightboxIsPlaying = useLightboxIsPlaying();
	let { playingId } = useLightboxStatus();
	const isInLightboxRevampExperiment = useAppSelector(
		isStorefrontLightboxRevampSelector,
	);
	if (isInLightboxRevampExperiment) {
		setLightboxIsPlaying = useSetAtom(setIsPlayingAtom);
		playingId = useAtomValue(playingIdAtom);
	}

	const isPlaying = playingId === id;
	const isPlayableMedia =
		mediaType === '360TOUR' ||
		mediaType === 'VIDEO' ||
		mediaType === 'VIDEO_UPLOAD';

	const stopMedia = () => setLightboxIsPlaying('');
	const pause = () => setIsPaused(true);
	const unpause = () => setIsPaused(false);

	const playMedia = () => {
		if (!isPlayableMedia) return;

		setLightboxIsPlaying(media.id);
		const action =
			mediaType === 'VIDEO' || mediaType === 'VIDEO_UPLOAD'
				? AnalyticsConstants.PLAY_VIDEO
				: AnalyticsConstants.PLAY_TOUR;
		const vendorAnalytics = new VendorAnalytics(vendor);
		const sourcePage =
			vendor.purchaseStatus === 'PAID'
				? AnalyticsConstants.PAID_STOREFRONT
				: AnalyticsConstants.UNPAID_STOREFRONT;
		track({
			event: AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
			properties: {
				action,
				sourcePage,
				sourceContent: AnalyticsConstants.PHOTO_GALLERY,
				...vendorAnalytics.baseEventProps(),
			},
		});
	};

	return render({
		media,
		playMedia,
		isPlaying,
		stopMedia,
		pause,
		unpause,
		isPaused,
	});
};

const mapStateToProps = (state: Redux.State) => ({
	vendor: state.vendor.vendor as Vendor.Decorated,
});

export default connect<StateProps, {}, OwnProps>(mapStateToProps)(Playable);
