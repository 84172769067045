import Icon from '@xo-union/tk-component-icons';
import React from 'react';
import type { FCWithChildren } from 'types/react-extended';
import Styles from './styles.scss';

export interface FullScreenLandscapeWrapperProps {
	closeModal: () => void;
}

const FullScreenLandscapeWrapper: FCWithChildren<
	FullScreenLandscapeWrapperProps
> = (props) => {
	const { children, closeModal } = props;
	return (
		<div className={Styles.container}>
			<div className={Styles.left} />
			{children}
			<div className={Styles.right}>
				<button
					aria-label="Close modal"
					name="close-btn-landscape-wrapper"
					onClick={closeModal}
					type="button"
					className={Styles.btn}
				>
					<Icon name="close" size="md" title="Close icon" />
				</button>
			</div>
		</div>
	);
};

export { FullScreenLandscapeWrapper };
