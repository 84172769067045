import { isReviewsLightboxAtom } from '@components/lightbox/atoms';
import { useCloseLightbox } from '@components/lightbox/hooks/use-close-lightbox';
import { useScreenDetails } from '@hooks/useScreenDetails';
import { Carousel } from '@xo-union/ui-carousel';
import { useAtomValue } from 'jotai';
import React, {
	type Dispatch,
	type FC,
	type SetStateAction,
	useCallback,
	useState,
} from 'react';
import { connect } from 'react-redux';
import Media from '../../../../../components/Media';
import Playable, {
	type PlayableRenderProps,
} from '../../../../../components/Media/playable';
import useCarouselSize from '../../../../../hooks/useCarouselSize';
import type { useInlineRfqForm } from '../../../../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import { ModalWrapper } from '../common/modal_wrapper/ModalWrapper';
import { MobilePhotoCount } from '../mobile_photo_count';
import useTrackLightboxInteraction from './hooks/use-track-lightbox-interaction';
import {
	getCarouselStyles,
	getImageAndPillStyles,
	getLightboxWrapperStyles,
} from './utils';

interface StateProps {
	vendor: Vendor.Decorated;
	vendorRaw: Vendor.Raw | null;
}

export interface OwnProps {
	carouselMedia: Vendor.Media[];
	isModalOpen: boolean;
	toggleModal: () => void;
	closeOverviewModal?: () => void;
	isQuickLinkClick?: boolean;
	overviewIndex?: number;
	setActiveImageIndex?: Dispatch<SetStateAction<number>>;
	mediaType?: Vendor.Media['mediaType'];
	context?: ReturnType<typeof useInlineRfqForm>;
}

export type LightboxModalProps = StateProps & OwnProps;

const LightboxModal: FC<LightboxModalProps> = (props) => {
	const {
		carouselMedia,
		isQuickLinkClick,
		overviewIndex = 0,
		mediaType,
		setActiveImageIndex,
		isModalOpen,
		toggleModal,
		closeOverviewModal,
		vendor,
		vendorRaw,
		context,
	} = props;
	const vendorName = vendor?.name ?? '';
	const isReviewsLightbox = useAtomValue(isReviewsLightboxAtom);
	const [lightboxIndex, setLightboxIndex] = useState(overviewIndex);
	const closeLightbox = useCloseLightbox();

	const [isLandscape, width, height] = useScreenDetails();

	const trackLightboxInteraction = useTrackLightboxInteraction(vendor);
	const onNavigationChange = useCallback(
		(index) => {
			if (setActiveImageIndex) {
				setActiveImageIndex(index);
			}
			setLightboxIndex((prevState) => {
				trackLightboxInteraction(
					prevState < index ? 'swipe right' : 'swipe left',
				);
				return index;
			});
			setLightboxIndex(index);
		},
		[setActiveImageIndex, trackLightboxInteraction],
	);

	const handleClose = () => {
		trackLightboxInteraction('dismiss');
		closeLightbox();
		toggleModal();
	};

	const responsive = useCallback(() => ({ slides: 1 }), []);

	const sourceContent = isReviewsLightbox
		? 'individual review gallery'
		: 'lightbox';

	return (
		<ModalWrapper
			hash={sourceContent.replace(' ', '-')}
			isModalOpen={isModalOpen}
			closeModal={handleClose}
			isLandscape={isLandscape}
			isQuickLinkClick={isQuickLinkClick}
			mediaLength={carouselMedia.length}
			mediaType={mediaType}
			sourceContent={sourceContent}
			isModalOnModal={!isReviewsLightbox}
			vendorName={vendorName}
			closeOverviewModal={closeOverviewModal}
			context={context}
		>
			<Carousel
				classes={getCarouselStyles(isLandscape)}
				gutter={0}
				responsive={responsive}
				navigationIndex={lightboxIndex}
				onNavigationIndexChange={onNavigationChange}
				{...useCarouselSize()}
			>
				{carouselMedia.map((media) => {
					const { image, pill } = getImageAndPillStyles(
						media,
						isReviewsLightbox,
					);
					const isQuickResponder = Boolean(
						vendorRaw?.vendorBehavior?.quickResponder,
					);

					return (
						<div
							className={getLightboxWrapperStyles(media, isQuickResponder)}
							key={media.id}
						>
							<Playable
								media={media}
								render={({ isPlaying }: PlayableRenderProps) => {
									if (isPlaying) return <></>;
									return (
										<MobilePhotoCount
											currentIndex={lightboxIndex}
											totalNumber={carouselMedia.length}
											cssClassOverride={pill}
										/>
									);
								}}
							/>
							<Media
								canPlay
								mediaItem={media}
								imageClass={image}
								width={width}
								height={height}
								fit
								tabIndexOverride={-1}
							/>
						</div>
					);
				})}
			</Carousel>
		</ModalWrapper>
	);
};

const mapStateToProps = (state: Redux.State): StateProps => ({
	vendor: state.vendor.vendor as Vendor.Decorated,
	vendorRaw: state.vendor.vendorRaw,
});

// biome-ignore lint/complexity/noBannedTypes: <explanation>
export default connect<StateProps, {}, OwnProps>(mapStateToProps)(
	LightboxModal,
);
